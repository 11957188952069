import React from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import Layout from "../components/Layout/layout"
import image from '../assets/images/background2.jpg'

const MusicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-image: ${({ image }) => `url(${image})`};
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
`;

const MenuWrapper = styled.div`
    display:flex;
    flex-direction: column;
    height: 10vh;
`


const ContentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height: 80vh;
    width: 100%;
`

const Content = styled.div`
    width: 80%;
    height: 70vh;
    overflow:auto;
    padding: 30px;
    opacity:0.8;
    color: white;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    ${({ theme }) => theme.media.phone}{
        width: 100%;
    }

`

const SpotifyPlayerWrapper = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    height: 40vh;

${({ theme }) => theme.media.phone}{
        width: 100%;
        height: 55vh;
    }

    ${({ theme }) => theme.media.tablet}{
        width: 80%;
        height: 40vh;
    }
`

const MusicPage = () => (
    <Layout>
        <MusicWrapper image={image}>
            <>
                <MenuWrapper>
                    <Menu showHome />
                </MenuWrapper>
                <ContentWrapper>
                    <Content>
                        <SpotifyPlayerWrapper>
                            <iframe title="spotify" src="https://open.spotify.com/embed/artist/1h38eYp11ADg0VFGw4qF5A" width="100%" height="100%" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </SpotifyPlayerWrapper>
                    </Content>
                </ContentWrapper>
                <Footer />
            </>        
        </MusicWrapper>
    </Layout>
)

export default MusicPage;